<template>
  <div class="charts">
    <b-card no-body>
      <div v-if="data && data.length > 0">
        <div class="wrapper">
          <div class="total">
            <strong>How many activities were generated?</strong>
            <div class="leads-count">{{ totalLeadsCount }}</div>
          </div>
        </div>
        <ag-charts-vue
          :options="options">
        </ag-charts-vue>
      </div>
      <b-skeleton height="100%" v-else></b-skeleton>
    </b-card>
  </div>
</template>

<script>
import SimpleHelper from '@/agGridV2/helpers/simple.helper'
import { AgChartsVue } from 'ag-charts-vue'

export default {
  name: 'lead-center-charts-activities',
  data() {
    return {
      totalLeadsCount: 0,
      options: null
    }
  },
  components: {
    AgChartsVue
  },
  props: ['data'],
  watch: {
    data() {
      this.totalLeadsCount = 0
      this.setOptions()
    }
  },
  created() {
    this.setOptions()
  },
  methods: {
    setOptions() {
      this.options = {
        height: 600,
        data: [],
        series: []
      }
      if (this.data) {
        this.options = {
          height: 600,
          data: this.data,
          series: [
            {
              type: 'area',
              xKey: 'day',
              yKey: 'calls',
              yName: 'Calls',
              tooltip: {
                renderer: function ({ datum, xKey, yKey }) {
                  return {
                    content: SimpleHelper.dayMonthYearStringToLabels(datum[xKey]) + ' : ' + datum[yKey],
                    title: 'Calls Count'
                  }
                }
              },
              strokeWidth: 1,
              fillOpacity: 0.1,
              marker: {
                size: 10
              }
            },
            {
              type: 'area',
              xKey: 'day',
              yKey: 'forms',
              yName: 'Forms',
              tooltip: {
                renderer: function ({ datum, xKey, yKey }) {
                  return {
                    content: SimpleHelper.dayMonthYearStringToLabels(datum[xKey]) + ' : ' + datum[yKey],
                    title: 'Forms Count'
                  }
                }
              },
              strokeWidth: 1,
              fillOpacity: 0.1,
              marker: {
                size: 10
              }
            },
            {
              type: 'area',
              xKey: 'day',
              yKey: 'chats',
              yName: 'Chats',
              tooltip: {
                renderer: function ({ datum, xKey, yKey }) {
                  return {
                    content: SimpleHelper.dayMonthYearStringToLabels(datum[xKey]) + ' : ' + datum[yKey],
                    title: 'Chats Count'
                  }
                }
              },
              strokeWidth: 1,
              fillOpacity: 0.1,
              marker: {
                size: 10
              }
            }
          ],
          legend: {
            enabled: true,
            item: {
              label: {
                formatter: (params) => {
                  let total = this.data.reduce((acc, obj) => {
                    let value = obj[params.itemId] ? Number(obj[params.itemId]) : 0
                    return isNaN(value) ? acc : acc + value
                  }, 0)
                  this.totalLeadsCount += total
                  return params.value + ` (${total})`
                }
              }
            }
          },
          axes: [
            {
              type: 'category',
              position: 'bottom',
              label: {
                formatter: (params) => {
                  return SimpleHelper.dayMonthYearStringToLabels(params.value)
                }
              }
            },
            {
              type: 'number',
              position: 'left'
            }
          ]
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.charts {
  .wrapper {
    display: flex;
    .total {
      width: 60%;
      .leads-count {
        font-size: 20px;
        font-weight: 600;
        padding-top: 10px;
      }
    }
  }
  margin-bottom: 15px;
}
.card {
  padding: 20px;
  border-radius: 0;
  height: 680px;
}
</style>
