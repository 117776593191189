<template>
  <div class="export-import-popup">
    <div>
      <label for="daterange"><strong>Date Range:</strong></label>
      <div id="daterange" class="date">
        <b-form-datepicker
          v-model="startDate"
          :max="maxEndDate"
          placeholder="Start Date"
          class="mb-2 custom-datepicker"
          locale="en-US"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        ></b-form-datepicker>
        <b-form-datepicker
          v-model="endDate"
          :min="startDate"
          placeholder="End Date"
          class="mb-2 custom-datepicker"
          locale="en-US"
          :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
        ></b-form-datepicker>
      </div>
    </div>
    <div>
      <label for="types"><strong>Types:</strong></label>
      <b-form-group>
        <b-form-checkbox-group
          id="types"
          v-model="types"
          :options="['form', 'chat', 'call']"
          name="types"
        ></b-form-checkbox-group>
      </b-form-group>
    </div>
    <div>
      <label for="emails"><strong>Emails:</strong></label>
      <b-form-input id="emails" v-model="emails"></b-form-input>
    </div>
    <button id="export-button" v-on:click="exportTo" :class="['button-outline-blue', 'mt-2', 'item', exportProgress ? 'disabled' : '']">
      Export
      <b-spinner v-if="exportProgress" :class="['ml-2']" style="width: 15px; height: 15px; border: 0.25em solid currentcolor; border-right-color: transparent;" label="Loading..."></b-spinner>
    </button>
    <div class="mt-2" v-show="result.url">
      Result: <a style="word-break: break-all" :href="result.url" target="_blank">{{ result.url }}</a>
    </div>
  </div>
</template>
<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      types: ['form', 'chat', 'call'],
      maxEndDate: null,
      endDate: null,
      startDate: null,
      emails: this.me().email || '',
      exportProgress: false,
      importProgress: false,
      deleteImportedProgress: false,
      result: {
        url: ''
      },
      spreadsheetId: null,
      importLogs: []
    }
  },
  computed: {
    selectedPlatform: {
      get() {
        return this.getSelectedPlatform()
      }
    }
  },
  methods: {
    ...mapGetters([
      'getSelectedPlatform',
      'me'
    ]),
    async exportTo() {
      try {
        this.exportProgress = true
        this.result = {
          url: ''
        }
        this.result = await MtApi.exportActivities({
          startDate: this.startDate,
          endDate: this.endDate,
          types: this.types,
          emails: this.emails,
          platform: this.selectedPlatform
        })
        this.exportProgress = false
      } catch (e) {
        this.exportProgress = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.export-import-popup {
  margin: 25px;
  overflow: visible;
  .date {
    display: flex;
    .custom-datepicker {
      padding: 2px;
      border: 2px solid #7987FF;
      border-radius: 0.25rem;
      &::v-deep {
        label.form-control {
          padding: inherit;
          position: inherit;
          display: flex;
          align-items: center;
        }
      }
      &:last-child {
        margin-left: 5px;
      }
    }
  }
  .form-group {
    padding: 0;
  }
  input {
    border: 2px solid #7987FF;
    padding: 0 10px;
  }
  .logs {
    max-height: 200px;
    overflow: auto;
  }
  .info-icon {
    background: blue;
    border-radius: 20px;
    color: white;
    font-size: 15px;
    margin-left: 5px;
  }
}
</style>
