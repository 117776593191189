<template>
  <div class="lead-center-management">
    <button id="charts-show-button" v-on:click="charts" :class="['button-outline-blue', 'item', showCharts ? 'router-link-active' : '']"><b-icon icon="bar-chart"></b-icon></button>
    <button id="refresh-all" @click="refreshAll" :class="['button-outline-blue', 'item', this.gridApi ? '' : 'disabled']">
      <span v-if="remainingCount[selectedPlatform._id]">{{ remainingCount[selectedPlatform._id] }}</span>
      <span v-else>AI</span>
    </button>
<!--    <button id="delete-selected-button" v-on:click="deleteSelected" :class="['button-outline-blue', 'item', selectedRows ? '' : 'disabled']"><b-icon icon="trash"></b-icon></button>-->
<!--    <button id="export-import-button" v-on:click="importFunction" :class="['button-outline-blue', 'item']">-->
<!--      <b-icon icon="arrow-down"></b-icon>-->
<!--    </button>-->
    <button id="export-import-button" v-on:click="exportFunction" :class="['button-outline-blue', 'item']">
      <b-icon icon="arrow-down"></b-icon>
    </button>
  </div>
</template>
<script>
import RefreshActivitiesPopup from '@/agGridV2/components/refresh-activities-popup.component.vue'
import { mapGetters, mapMutations } from 'vuex'
import MtApi from '../../../agGridV2/helpers/mt-api.helper'
import ExportPopup from '../../components/export-popup.component.vue'
import ImportPopup from '../../components/import-popup.component.vue'

export default {
  name: 'header-lead-center-management',
  data() {
    return {
      selectedRows: false,
      gridApi: null,
      remainingCount: {},
      selectedPlatformId: null
    }
  },
  computed: {
    showCharts: {
      get() {
        return this.getShowChartsLeadCenter()
      },
      set(value) {
        this.setShowChartsLeadCenter(value)
      }
    },
    selectedPlatform() {
      return this.getSelectedPlatform()
    }
  },
  async mounted() {
    this.$emitter.on('grid_api', async (api) => {
      this.gridApi = api
    })
    this.$emitter.on('activities_process_remaining_count', (object) => {
      this.$set(this.remainingCount, object.platform, object.count)
    })
    this.$emitter.on('activities_refresh_start', (object) => {
      this.$set(this.remainingCount, object.platform, object.count)
    })
    const processedCount = await MtApi.getProcessedCount(this.selectedPlatform._id)
    if (processedCount) {
      this.$emitter.emit('activities_process_remaining_count', {
        platform: this.selectedPlatform._id,
        count: processedCount
      })
    }
    this.$emitter.on('selected_rows_event', async (params) => {
      this.gridApi = params.api
      const selectedRows = params.api.getSelectedRows()
      if (selectedRows.length > 0) {
        this.selectedRows = true
      } else {
        this.selectedRows = false
      }
    })
  },
  beforeDestroy() {
    this.$emitter.off('selected_rows_event')
  },
  methods: {
    ...mapMutations([
      'setShowChartsLeadCenter'
    ]),
    ...mapGetters([
      'getShowChartsLeadCenter',
      'getSelectedPlatform'
    ]),
    deleteSelected() {
      const selectedRows = this.gridApi.getSelectedRows()
      this.gridApi.applyServerSideTransaction({ remove: selectedRows })
      this.selectedRows = false
      for (const row of selectedRows) {
        MtApi.deleteActivity(row._id)
      }
    },
    charts() {
      this.showCharts = !this.showCharts
    },
    exportFunction() {
      this.$modal.show(
        ExportPopup,
        {}, {
          width: '500px',
          height: 'auto',
          classes: ['export-import-popup-wrapper']
        })
    },
    importFunction() {
      this.$modal.show(
        ImportPopup,
        {}, {
          width: '800px',
          height: '500px',
          classes: ['export-import-popup-wrapper']
        })
    },
    refreshAll() {
      this.$modal.show(
        RefreshActivitiesPopup,
        {
          gridApi: this.gridApi
        }, { width: '500px' })
    }
  }
}
</script>

<style lang="scss" scoped>
.lead-center-management {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  .item {
    margin-left: 0;
    min-width: 50px;
    display: flex;
    justify-content: center;
  }
}
</style>
