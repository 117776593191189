<template>
  <div class="import-popup">
    This Is Import
  </div>
</template>
<script>
import MtApi from '@/agGridV2/helpers/mt-api.helper'
import { mapGetters } from 'vuex'

export default {
  data() {
    return {

    }
  },
  computed: {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.import-popup {
  margin: 25px;
  overflow: visible;
}
</style>
